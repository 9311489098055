<template>
	<!-- 交易通资料变更 -->
	<div class="margin-t-20 width-fill">
		<div class="width-fill bg-fff my-account flex-row-space-between-center">
			<div class="font-size18 font-weight700">更新资料</div>
			<div class="closeTtn" @click="closeTtn">取消</div>
		</div>
		<div v-loading="loading" :class="themeClass" class="contBox width-fill bg-fff margin-t-10">
			<div class="form-Box">
				<div class="title flex-row-align-center text-center">
					<div class="font-size14">
						<span style="color: #CC0000;font-weight: 700;">* 账户风险：账户信息存在变更 </span>
						<span>（为保障您的资金安全及交易顺畅，请及时更新资料）</span>
					</div>
				</div>
				<div class="title flex-row-align-center text-center">
					<div class="font-size14">
						<span style="color: #000;font-weight: 700;">提示信息：如您已在工商系统进行变更，请与变更日起7个工作日后再进行变更操作</span>
					</div>
				</div>
				<el-form :model="bindingform" :rules="rules" ref="bindingform" label-position="left" label-width="150px"
					class="demo-ruleForm">
					<div class="itemBox">
						<div class="itemColor">
							<i class="iconfont icon-tishi6"></i>
							<span style="padding-left: 5px;">以下信息请与营业执照信息一致</span>
						</div>
						<el-form-item label="公司名称" prop="fAccountName" style="margin-left: 50px;">
							<el-input v-model.trim="bindingform.fAccountName" disabled placeholder="请输入企业名称"></el-input>
						</el-form-item>
						<el-form-item label="统一信用代码" prop="fPrfnbr" style="margin-left: 50px;">
							<el-input v-model.trim="bindingform.fPrfnbr" disabled placeholder="请输入统一信用代码"></el-input>
						</el-form-item>
						<el-form-item v-if="item.rule.fAnnexTypeID == '502'" v-for="(item, index) in uploadRule"
							:key="index" :label="item.rule.fRuleName" class='testClass'
							:required="item.rule.fIsNecessary == 1 ? true : false" style="margin-left: 50px;">
							<div class="padding-10 border-F2F2F2" style="width: 330px; box-sizing: border-box;">
								<UploadImgList :rules="item.rule" :ISconfirm="ISconfirm"
									:uploadToken="uploadToken[index]" class="uploadimglist" :limit="1"
									:accept="limitTypeArr[index]" :ISshowDelete="false" v-model="imgListName[index]"
									uploadType="image" @success="uploadSuccess" />
							</div>
							<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;"
								v-if="limitTypeArr[index]||item.rule.fMaxSize">
								* 提示：
								<span>格式为{{item.rule.fFileType}}</span>
								<span v-if="item.rule.fMaxSize">,大小不超过{{ item.rule.fMaxSize }}MB</span>
							</div>
						</el-form-item>
					</div>

					<div class="itemBox">
						<div class="itemColor">
							<i class="iconfont icon-tishi6"></i>
							<span style="padding-left: 5px;"> 以下信息请与法人信息一致</span>
						</div>
						<el-form-item label="姓名" prop="fMbrnam" style="margin-left: 50px;">
							<el-input v-model.trim="bindingform.fMbrnam" placeholder="请输入成员名称"
								:disabled="bindingform.fMenberFrontFilePath ? false : true"></el-input>
						</el-form-item>
						<el-form-item label="身份证号" prop="fDocnbr" style="margin-left: 50px;">
							<el-input v-model="bindingform.fDocnbr" placeholder="请输入身份证号码"
								:disabled="bindingform.fMenberFrontFilePath ? false : true"></el-input>
						</el-form-item>
						<el-form-item label="证件有效开始日期" prop="fDocbeg" style="margin-left: 50px;">
							<el-input v-model="bindingform.fDocbeg" placeholder="请输入证件开始日期 例: 1999-12-31"
								:disabled="bindingform.fMenberOppositeFilePath ? false : true"></el-input>
						</el-form-item>
						<el-form-item label="证件截止日" prop="fDocvld" style="margin-left: 50px;">
							<el-input v-model="bindingform.fDocvld" placeholder="请输入证件有效期 例: 1999-12-31 或 长期"
								:disabled="bindingform.fMenberOppositeFilePath ? false : true"></el-input>
						</el-form-item>
						<el-form-item label="联系电话" prop="fMbrtel" style="margin-left: 50px;">
							<el-input v-model.trim="bindingform.fMbrtel" placeholder="请输入联系电话"></el-input>
						</el-form-item>
						<el-form-item v-if="item.rule.fAnnexTypeID == '213'" v-for="(item, index) in uploadRule"
							:key="index" :label="item.rule.fRuleName" class='testClass'
							:required="item.rule.fIsNecessary == 1 ? true : false" style="margin-left: 50px;">
							<div class="padding-10 border-F2F2F2" style="width: 330px; box-sizing: border-box;">
								<UploadImgList :rules="item.rule" :ISconfirm="ISconfirm"
									:uploadToken="uploadToken1[index]" class="uploadimglist" :limit="1"
									:accept="limitTypeArr1[index]" v-model="imgListName[index]" uploadType="image"
									@success="uploadSuccess1" @removeIdChange="removeIdChange" />
							</div>
							<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;"
								v-if="limitTypeArr1[index]||item.rule.fMaxSize">
								* 提示：
								<span>格式为{{item.rule.fFileType}}</span>
								<span v-if="item.rule.fMaxSize">,大小不超过{{ item.rule.fMaxSize }}MB</span>
							</div>
						</el-form-item>
						<el-form-item v-if="item.rule.fAnnexTypeID == '214'" v-for="(item, index) in uploadRule"
							:key="index" :label="item.rule.fRuleName" class='testClass'
							:required="item.rule.fIsNecessary == 1 ? true : false" style="margin-left: 50px;">
							<div class="padding-10 border-F2F2F2" style="width: 330px; box-sizing: border-box;">
								<UploadImgList :rules="item.rule" :ISconfirm="ISconfirm"
									:uploadToken="uploadToken1[index]" class="uploadimglist" :limit="1"
									:accept="limitTypeArr1[index]" v-model="imgListName[index]" uploadType="image"
									@success="uploadSuccess1" @removeIdChange="removeIdChange" />
							</div>
							<div class="font-size12 font-color-FF9900 margin-t-4" style="line-height: normal;"
								v-if="limitTypeArr1[index]||item.rule.fMaxSize">
								* 提示：
								<span>格式为{{item.rule.fFileType}}</span>
								<span v-if="item.rule.fMaxSize">,大小不超过{{ item.rule.fMaxSize }}MB</span>
							</div>
						</el-form-item>
					</div>

					<!-- <el-form-item label="" id="setform" prop="radio">
					<div style="line-height: 20px !important;">
						<el-checkbox-group v-model="bindingform.radio">
							<el-checkbox label="我已阅读并同意"></el-checkbox>
						</el-checkbox-group>
						<span v-for="(item,index) in zftAgreement" :key='index' style="display: inline-block;line-height: 20px;">
							<span style="cursor: pointer;" class="color-theme font-size12 lh21" @click="showAgreeDia(item.fAgreementHistID)">《{{item.fTitle}}》</span>
						</span>
					</div>
				</el-form-item> -->
					<el-button class="background-color-theme" @click="save" :disabled="buttonloading"
						:loading='buttonloading'>提交</el-button>
				</el-form>

				<div class="bg-box"></div>
			</div>
			<agreementDialog :iContents='iContent' ref="areDialog"></agreementDialog>
			<!-- <BranchSelection v-if="BranchSelection" :showModel='BranchSelection' :id="fBankCode" @change="branchChange"></BranchSelection> -->
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	// import Graphic from '@/components/Graphic';
	import agreementDialog from '@/components/agreementDialog';
	// import BranchSelection from './BranchSelection';
	import UploadImgList from '@/components/upload/UploadImgList';
	import IdentityCodeValid from '@/utils/checkIdent';
	export default {
		data() {
			let validateIDCard = (rule, value, callback) => {
				if (!IdentityCodeValid(value)) {
					callback(new Error('请输入正确的身份证号'));
				} else {
					callback();
				}
			};
			// 验证手机号的校验规则
			let checkMobile = (rule, value, callback) => {
				// 验证手机号的正则表达式
				const regMobile = /^1[3-9]\d{9}$|^0\d{2,3}-\d{7,8}$/;
				if (regMobile.test(value)) {
					// 合法的手机号
					return callback();
				}
				// 不合法
				callback(new Error("请输入正确的手机号"));
			};
			// 比例范围校验
			let MenberSyRateMax = (rule, value, callback) => {
				if (value > 0 && value <= 100) {
					// 合法的手机号
					return callback();
				}
				// 不合法
				callback(new Error("比例必须大于0小于等于100"));
			};
			return {
				imgListName: [],
				ISconfirm: true,
				uploadToken: [], //上传图片token
				uploadToken1: [], //上传图片token
				limitTypeArr: [],
				limitTypeArr1: [],
				uploadRule: [],
				imgList: [], //显示图片
				bankImg: '',
				bankName: '',
				fCardMediaTypeName: '',
				fCardMediaType: '',
				message: '',
				bindingform: {
					fAccountID: '', // 企业id
					fAccountName: '', // 企业名称
					fPrfnbr: '', // 统一社会信用代码
					fIdentityFilePath: '', // 营业执照路径

					fAccount: '', // 绑定账户

					fMbrnam: '', // 成员姓名
					fDocnbr: '', // 证件号
					fDocvld: '', // 证件有效期
					fDocbeg: '', // 证件开始日期
					fMbrtel: '', // 联系电话
					fMenberFrontFilePath: '', // 法人身份证正面
					fMenberOppositeFilePath: '', // 法人身份证背面


					radio: false, //协议的按钮
				},
				bankImg: '',
				bankName: '',
				fCardMediaTypeName: '',
				fCardMediaType: 0,
				message: '',

				noticeShow: false,
				iContent: '',
				fAccountname: '',
				zftAgreement: [],
				dialogFormVisible: false,
				formLabelWidth: "80px",
				loading: true,
				innerVisibles: false,
				title: "", //标题
				type: 1, //1 中金,2 账服通
				userinfo: {}, //用户信息
				bankList: [], //开户行
				msg: false,
				BranchSelection: false,
				buttonloading: false,
				fBankCode: '',
				rules: {
					fAccountName: [{
						required: true,
						message: "请输入公司名称",
						trigger: "change",
					}],
					fPrfnbr: [{
						required: true,
						message: "请输入统一社会信用代码",
						trigger: "change",
					}],
					fMbrnam: [{
						required: true,
						message: "请输入成员名称",
						trigger: "change",
					}],
					fDocnbr: [{
							required: true,
							message: "请输入身份证号",
							trigger: "change",
						},
						{
							validator: validateIDCard,
							trigger: "blur"
						}
					],
					fDocvld: [{
							required: true,
							message: "请选择证件有效期",
							trigger: "change",
						},
						{
							validator: (rule, value, callback) => {
								if (value == '长期') {
									callback()
								} else {
									const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
									if (!dateRegex.test(value)) {
										callback(new Error('请输入有效的日期 (格式: yyyy-MM-dd)'));
									} else {
										callback();
									}
								}
							},
							trigger: 'blur'
						}
					],
					fDocbeg: [{
							required: true,
							message: "请选择证件开始日期",
							trigger: "change",
						},
						{
							validator: (rule, value, callback) => {
								const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
								if (!dateRegex.test(value)) {
									callback(new Error('请输入有效的日期 (格式: yyyy-MM-dd)'));
								} else {
									callback();
								}
							},
							trigger: 'blur'
						}
					],
					fMbrtel: [{
							required: true,
							message: "请输入联系电话",
							trigger: "change",
						},
						{
							validator: checkMobile,
							trigger: "blur",
						},
					],


					fAccount: [{
						required: true,
						message: "请输入银行卡号",
						trigger: "blur"
					}, {
						min: 12,
						message: '银行账号不少于12位',
						trigger: 'blur'
					}],
					radio: [{
						required: true,
						validator: (rule, value, callback) => {
							if (value != true) {
								callback(new Error('请阅读并同意注册协议'));
							} else {
								callback();
							}
						},
						trigger: 'change'
					}]

				},

				files: [],
				annexServerUrl: '',
				fBillID: '',
				fDetail: [],
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			...mapGetters(["getUserInfo"]),
		},
		components: {
			agreementDialog,
			// Graphic,
			// BranchSelection
			UploadImgList
		},
		created() {
			// this.ruleForm = {};
			this.title = this.$route.query.title;
			this.type = this.$route.query.type
			this.fAccountname = this.$route.query.fAccountname;

			this.getImgUrl()
			this.loading = false
		},
		mounted() {},
		methods: {
			removeIdChange(e) {
				console.log(111111111111111111, e)
				if (e.includes(this.bindingform.fMenberFrontFilePath)) {
					this.bindingform.fMenberFrontFilePath = ''
				}
				if (e.includes(this.bindingform.fMenberOppositeFilePath)) {
					this.bindingform.fMenberOppositeFilePath = ''
				}
			},
			closeTtn() {
				this.$router.push({
					path: '/businessme/JYTwallet',
					query: {
						title: '交易通'
					}
				})
			},
			closeNoticeShows() {
				this.noticeShow = false;
			},
			shows() {
				this.noticeShow = true;
			},
			showAgreeDia(a) {
				this.noticeShow = true;
				this.iContent = a;
				this.$refs.areDialog.show();
			},
			getImgUrl() {
				//上传图片前缀
				this.ApiRequestPostNOMess(
					"/api/mall/ebbase/para-value/get-config"
				).then(
					(result) => {
						this.annexServerUrl = result.obj.annexServerUrl
						console.log('11111111111', this.$route.query.id)
						if (this.$route.query.id) {
							this.getInfo('api/mall/ebpayment/payment-jyt-account-change-bill/get', {
								id: this.$route.query.id
							});
						} else {
							this.getInfo('api/mall/ebpayment/payment-jyt-account-change-bill/get-curret-warning', {});
						}
					},
					(rej) => {
						if (this.$route.query.id) {
							this.getInfo('api/mall/ebpayment/payment-jyt-account-change-bill/get', {
								id: this.$route.query.id
							});
						} else {
							this.getInfo('api/mall/ebpayment/payment-jyt-account-change-bill/get-curret-warning', {});
						}
					}
				)
			},
			//   获取用户信息
			getInfo(url, data) {
				this.ApiRequestPost(url, data).then(
					(result) => {
						this.fBillID = result.obj.fBillID
						this.fDetail = result.obj.fDetail
						// this.fDetailID = result.obj.fBillID
						result.obj.annexList.forEach(item => {
							if (item.fIdentificationTypeName == '营业执照') {
								// this.imgList = [item]
								this.bindingform.fIdentityFilePath = item.fPath
							} else if (item.fAnnexTypeID == '213') {
								this.bindingform.fMenberFrontFilePath = item.fPath
								if (item.fPath) {
									this.uploadSuccess1([{
										fPath: item.fPath,
										fAnnexTypeID: '213'
									}], this.annexServerUrl)
								}
							} else if (item.fAnnexTypeID == '214') {
								this.bindingform.fMenberOppositeFilePath = item.fPath
								if (item.fPath) {
									this.uploadSuccess1([{
										fPath: item.fPath,
										fAnnexTypeID: '214'
									}], this.annexServerUrl)
								}
							}
						})
						this.bindingform.fAccountID = result.obj.fAccountID
						this.bindingform.fAccountName = result.obj.fAccountName
						this.bindingform.fPrfnbr = result.obj.fPrfnbr
						this.bindingform.fMbrnam = result.obj.fDetail[0].fMbrnam
						this.bindingform.fDocnbr = result.obj.fDetail[0].fDocnbr
						if (result.obj.fDetail[0].fDocvld == '9999-12-31') {
							this.bindingform.fDocvld = '长期'
						} else {
							this.bindingform.fDocvld = result.obj.fDetail[0].fDocvld
						}
						this.bindingform.fDocbeg = result.obj.fDetail[0].fDocbeg
						this.bindingform.fMbrtel = result.obj.fDetail[0].fMbrtel
						result.obj.annexList.forEach(item => {
							if (item.fAnnexTypeID == '502' || item.fAnnexTypeID == '213' || item
								.fAnnexTypeID == '214') {
								this.files.push(item)
							}
						})
						this.getAdjustBillTypeAnnexRule()
					},
					(rej) => {}
				);
			},
			// 获取营业执照上传规则
			getAdjustBillTypeAnnexRule() {
				this.uploadRule = [] //这里请求前先	清除
				this.imgListName = [] //去除图片列表
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-enterprise-adjust-bill-type-annex-rule', {
						"id": '2'
					})
					.then(res => {
						res.forEach(item => {
							if (item.rule.fAnnexTypeID == "502") {
								this.uploadRule.push(item)
							}

						})
						let Token = {};
						let Type = [];
						this.uploadRule.forEach((item, index) => {
							Type[index] = item.rule.fFileType.split(',');
							Token.token = item.token;
							this.uploadToken.push(Token);
						});
						Type.forEach(item => {
							let imitTypeStr = ''
							item.forEach(item1 => {
								imitTypeStr = imitTypeStr + '.' + item1 + ','
							})
							this.limitTypeArr.push(imitTypeStr)
						})
						this.getAdjustBillTypeAnnexRule1()
					}, error => {});
			},
			// 获取身份证上传规则
			getAdjustBillTypeAnnexRule1() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-perfect-data-rule', {
						"id": '2'
					})
					.then(res => {
						res.forEach(item => {
							this.uploadRule.push(item)
						})
						let Token = {};
						let Type = [];
						this.uploadRule.forEach((item, index) => {
							Type[index] = item.rule.fFileType.split(',');
							Token.token = item.token;
							this.uploadToken1.push(Token);
						});
						Type.forEach(item => {
							let imitTypeStr = ''
							item.forEach(item1 => {
								imitTypeStr = imitTypeStr + '.' + item1 + ','
							})
							this.limitTypeArr1.push(imitTypeStr)
						})
						let _this = this
						this.uploadRule.forEach((item, index) => {
							_this.files.forEach((item1, index1) => {
								if (item.rule.fAnnexTypeID == item1.fAnnexTypeID) {
									_this.imgListName[index] = [];
									_this.imgListName[index].push({
										fPath: item1.fPath,
										ruleId: item.rule.fRuleID
									})
								}
							});
						});
						this.formLoading = false;
					}, error => {
						this.formLoading = false;
					});
			},
			//上传成功识别身份证
			uploadSuccess1(e, url) {
				let param = {
					imageUrl: url + e[0].fPath
				}
				if (e[0].fAnnexTypeID == '213') {
					this.bindingform.fMenberFrontFilePath = e[0].fPath
				} else if (e[0].fAnnexTypeID == '214') {
					this.bindingform.fMenberOppositeFilePath = e[0].fPath
				}
				this.ApiRequestPost('api/mall/tencent-cloud/ocr-id-card-by-image-url', param)
					.then(res => {
						if (res.isSucceed) {
							if (e[0].fAnnexTypeID == '213') {
								if (res.idNum) {
									this.bindingform.fMbrnam = res.name
									this.bindingform.fDocnbr = res
										.idNum
								} else {
									this.$message('请上传身份证人面像');
								}
							} else if (e[0].fAnnexTypeID == '214') {
								if (!res.idNum) {
									let arr = res.validDate.split('-')
									if (arr[1] == '长期') {
										this.bindingform.fDocvld =
											'长期'
									} else {
										let arr1 = arr[1].split('.')
										this.bindingform.fDocvld =
											arr1.join('-')
									}
									let arr2 = arr[0].split('.')
									this.bindingform.fDocbeg =
										arr2.join('-')
								} else {
									this.$message('请上传身份证国徽面');
								}
							}
						} else {
							this.$message('身份证识别失败，请重新上传');

						}
						this.isUpload = true;
					}, error => {
						this.isUpload = true;
					});
			},

			//上传成功识别营业执照
			uploadSuccess(e, url) {
				if (e[0].fAnnexTypeID != '502') {
					return
				}
				let param = {
					imageUrl: url + e[0].fPath
				}
				this.bindingform.fIdentityFilePath = e[0].fPath
				this.ApiRequestPost('/api/mall/tencent-cloud/ocr-biz-license-by-image-url', param)
					.then(res => {
						if (res.isSucceed) {
							this.bindingform.fAccountName = res.name
							this.bindingform.fPrfnbr = res.regNum
						} else {
							this.$message('营业执照识别失败，请重新上传');
						}
						this.isUpload = true;
					}, error => {
						this.isUpload = true;
					});
			},
			save() {
				// for (let item of this.uploadRule) {
				// 	let imageList = this.imgListName;
				// 	if (item.rule.fIsNecessary == 1) {
				// 		let flag = {}
				// 		for (let item1 of imageList) {
				// 			if (item1 && item1 != []) {
				// 				flag = item1.find(findItem => findItem.ruleId == item.rule.fRuleID);
				// 				if (flag) {
				// 					break;
				// 				}
				// 			}
				// 		}
				// 		flag = flag ? flag : {};
				// 		if (!flag.ruleId) {
				// 			this.$message({
				// 				message: item.rule.fRuleName + '不能为空',
				// 			});
				// 			return
				// 		}
				// 	}
				// }
				this.$refs.bindingform.validate((valid) => {
					if (valid) {
						if (this.message !== '') {
							this.$message.warning(this.message)
							return
						} else {
							this.toSave()
						}
					} else {
						return false;
					}
				});

			},
			toSave() {
				if (this.bindingform.fMenberOppositeFilePath == '') {
					this.$message('请上传身份证国徽面，请重新上传');
					return
				}
				if (this.bindingform.fMenberFrontFilePath == '') {
					this.$message('请上传身份证人面像，请重新上传');
					return
				}
				if (this.bindingform.fDocvld == '长期') {
					this.bindingform.fDocvld = '9999-12-31'
				}
				// if (this.bindingform.radio == false) {
				//   return this.tipsInfo('请阅读并同意注册协议');
				// }
				let parms = {
					fBillID: this.fBillID,
					fDetail: [{
							fDetailID: this.fDetail[0].fDetailID,
							fMbrnam: this.bindingform.fMbrnam,
							fDocnbr: this.bindingform.fDocnbr,
							fDocbeg: this.bindingform.fDocbeg,
							fDocvld: this.bindingform.fDocvld,
							fMbrtel: this.bindingform.fMbrtel,
							fFrontPathName: this.bindingform.fMenberFrontFilePath, // 人像照片
							fOppositePathName: this.bindingform.fMenberOppositeFilePath, // 国徽照
							fMbradr: '',
							frv: this.fDetail[0].frv,
							rowStatus: this.fDetail[0].rowStatus
						}

					]
				}
				this.buttonloading = true
				this.ApiRequestPostNOMess('api/mall/ebpayment/payment-jyt-account-change-bill/change-account-name', parms)
					.then(
						(res) => {
							this.buttonloading = false;
							// if (res.obj.fStatus) {
							// 	this.$message.success(res.obj.fMessage)
							// } else {
							// 	this.$message.warning(res.obj.fMessage)
							// }
							this.$router.push({
								path: '/businessme/JYTSubmitResults',
								query: {
									title: '变更资料结果',
									status: res.obj.fStatus,
									message: res.obj.fMessage
								}
							})
						},
						(rej) => {
							this.buttonloading = false;
						}
					).catch(err => {
						this.buttonloading = false;
						this.$router.push({
							path: '/businessme/JYTSubmitResults',
							query: {
								title: '变更资料结果'
							}
						})
					});

			}
		},
	};
</script>

<style lang="scss" scoped="scoped">
	.closeTtn {
		text-align: center;
		border: 1px solid #0066FF;
		color: #0066FF;
		width: 98px;
		height: 32px;
		line-height: 32px;
		cursor: pointer;
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		background-color: #0173FE;
		color: #FFFFFF;
	}

	/deep/ .tip {
		width: 390px;
		line-height: 16px;
	}

	.codeRlBox {
		height: 30px;
		line-height: 30px;
	}

	.icon-biaodan {
		color: #1c9dff;
	}

	/deep/ .el-form-item__label {
		padding-right: 19px;
	}

	/deep/ .el-input__inner {
		width: 330px;
		height: 32px;
		line-height: 32px;
		// padding: 0 10px;
		border: 1px solid #ccc !important;
	}

	.sendCode {
		margin-right: 40px;
	}

	.title {
		height: 20px;
		line-height: 20px;
		margin: 0px auto 25px auto;
		margin-left: 242px;
	}

	.contBox {
		overflow: hidden;
	}

	.demo-ruleForm {
		width: 600px;
		margin: 0px auto;
		margin-left: 200px;
		position: relative;
		z-index: 1;
	}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.el-button {
		width: 409px;
		height: 45px;
		margin-left: 100px;
	}

	#setform>div {
		line-height: 20px !important;
	}

	.button-Branch {
		position: absolute;
		z-index: 2;
		top: 5px;
		left: 310px;
		width: 70px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.bankbox {
		cursor: no-drop;
	}

	.debitcard {
		display: inline-block;
		color: #fff;
		background-color: #0164ff;
		padding: 0px 8px;
		border-radius: 5px;
		margin-left: 10px;
		line-height: 25px;
		font-size: 14px;
	}

	.creditcard {
		display: inline-block;
		color: #fff;
		background-color: #ff575a;
		padding: 0px 8px;
		border-radius: 5px;
		margin-left: 10px;
		line-height: 25px;
		font-size: 14px;
	}

	.imglistBox {
		width: 100%;
		margin: 10px 10px;
		margin-left: 212px;
		margin-bottom: 10px
	}

	.itemBox {
		// padding: 10px;
		border: 1px solid #0164ff;
		border-radius: 5px 5px 0 0;
		margin-bottom: 20px;
	}

	.itemBoxtit {
		font-size: 15px;
		font-weight: 700;
		color: #000;
		line-height: 30px;
	}

	.itemColor {
		border-radius: 5px 5px 0 0;
		height: 27px;
		line-height: 27px;
		padding-left: 15px;
		color: #0164ff;
		font-size: 13px;
		background: #e6edfd;
		margin-bottom: 10px;
	}

	//协议复选框样式
	::v-deep .el-checkbox-group {
		width: fit-content !important;
		display: inline !important;
	}
</style>